<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            Relatórios Mensais
            <div class="actions">
              <div class="filter">
                <div class="filter-item">
                  <label>Mês</label>
                  <select v-model="selectedMonth" @change="getReport(selectedMonth - 1, selectedYear)">
                    <option v-for="(month, idx) of monthsList" :key="'month-'+idx" :value="idx+1">{{month}}</option>
                  </select>
                </div>
                <div class="filter-item">
                  <label>Ano</label>
                  <select v-model="selectedYear" @change="getReport(selectedMonth - 1, selectedYear)">
                    <option v-for="year of yearsList" :key="'year-'+year" :value="year">{{year}}</option>
                  </select>
                </div>
              </div>
              <download-excel
                class="btn btn-download-excel"
                :data="excelData"
                :fields="excelFields"
                worksheet="Vendas"
                :name="'sales-' + new Date().getTime() + '.xls'"
              >
                <img src="icon-excel.svg" /> Export Excel
              </download-excel>
            </div>
          </CCardHeader>

          <CCardBody style="padding-bottom: 0;">
            <!-- VENDAS BEM SUCEDIDAS -->
            <CRow>
              <CCol col="12" sm="6" lg="3">
                <CWidgetIcon
                  :header="'€ ' + this.totalSales"
                  text="Total recebido"
                  color="success"
                >
                  <CIcon name="cil-euro" width="24"/>
                </CWidgetIcon>
              </CCol>

              <CCol col="12" sm="6" lg="3">
                <CWidgetIcon
                  :header="this.numSales"
                  text="Num. vendas recebidas"
                  color="success"
                >
                  <CIcon name="cil-check" width="24"/>
                </CWidgetIcon>
              </CCol>

              <CCol col="12" sm="6" lg="3">
                <CWidgetIcon
                  :header="'€ ' + this.liquid"
                  text="Vl. Líquido (77%)"
                  color="success"
                >
                  <CIcon name="cil-check" width="24"/>
                </CWidgetIcon>
              </CCol>

              <CCol col="12" sm="6" lg="3">
                <CWidgetIcon
                  :header="'€ ' + this.commission"
                  text="Comissão (23%)"
                  color="success"
                >
                  <CIcon name="cil-check" width="24"/>
                </CWidgetIcon>
              </CCol>
            </CRow>

            <!-- TOTAIS E VENDAS NÃO CONLUÍDAS -->
            <CRow>
              <CCol col="12" sm="6" lg="3">
                <CWidgetIcon
                  :header="'€ ' + this.totalInsurances"
                  text="Total vendas"
                  color="primary"
                >
                  <CIcon name="cil-euro" width="24"/>
                </CWidgetIcon>
              </CCol>

              <CCol col="12" sm="6" lg="3">
                <CWidgetIcon
                  :header="this.numInsurances"
                  text="Num. vendas"
                  color="primary"
                >
                  <CIcon name="cil-check" width="24"/>
                </CWidgetIcon>
              </CCol>
              
              <CCol col="12" sm="6" lg="3">
                <CWidgetIcon
                  :header="'€ ' + this.totalNoSales"
                  text="Total não recebido"
                  color="danger"
                >
                  <CIcon name="cil-euro" width="24"/>
                </CWidgetIcon>
              </CCol>

              <CCol col="12" sm="6" lg="3">
                <CWidgetIcon
                  :header="this.numNoSales"
                  text="Num. vendas Incompletas"
                  color="danger"
                >
                  <CIcon name="cil-ban" width="24"/>
                </CWidgetIcon>
              </CCol>
            </CRow>
          </CCardBody>
          
          <CCardBody>
            <CDataTable
              class="mb-0 table-outline"
              hover
              :items="tableInsurances"
              :fields="tableFields"
              head-color="light"
              sorter
            >
              <td
                slot="id"
                class="text-center"
                slot-scope="{ item }"
                :columnFilter="{ external: true, lazy: true }"
              >
                <div>
                  {{ item.id }}
                </div>
              </td>

              <td
                slot="order_code"
                class="text-center"
                slot-scope="{ item }"
                :columnFilter="{ external: true, lazy: true }"
              >
                <div>
                  {{ item.order_code }}
                </div>
              </td>

              <td slot="createdAt" slot-scope="{ item }">
                <div>
                  {{ new Date(item.createdAt).toLocaleDateString('pt-PT') }}
                </div>
              </td>

              <td slot="plano" slot-scope="{ item }">
                <div>{{ item.plan }}</div>
              </td>
              <td slot="price" slot-scope="{ item }">
                <div>{{ item.price }} €</div>
              </td>
              <td
                slot="payment_status"
                slot-scope="{ item }"
                :columnFilter="{ external: true, lazy: true }"
              >
                <CBadge :color="getBadge(item.payment_status)">{{
                  item.payment_status
                }}</CBadge>
              </td>

              <td slot="detail" slot-scope="{ item }">
                <CButton
                  block
                  variant="outline"
                  color="primary"
                  @click="getDados(item.id)"
                  >Ver</CButton
                >
              </td>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal title="Insurance" size="lg" :show.sync="largeModal">
      <CRow v-for="insurances in this.modalInsurances" :key="insurances">
        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <strong>Information </strong>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-md-4">
                  <label>Number:</label>
                  <p>{{ insurances.id }}</p>
                </div>
                <div class="col-md-4">
                  <label>Plan:</label>
                  <p>{{ insurances.plan }}</p>
                </div>
                <div class="col-md-4">
                  <label>Status:</label>
                  <p>
                    <CBadge :color="getBadge(insurances.payment_status)">{{
                      insurances.payment_status
                    }}</CBadge>
                  </p>
                </div>
                <div class="col-md-4">
                  <label>Countries:</label>
                  <p>{{ insurances.countries }}</p>
                </div>

                <div class="col-md-4">
                  <label>Initial date:</label>
                  <p>
                    {{
                      new Date(insurances.initial_date).toLocaleDateString(
                        "pt-PT"
                      )
                    }}
                  </p>
                </div>
                <div class="col-md-4">
                  <label>Final date:</label>
                  <p>
                    {{
                      new Date(insurances.final_date).toLocaleDateString(
                        "pt-PT"
                      )
                    }}
                  </p>
                </div>

                <div class="col-md-4">
                  <label>Order datetime:</label>
                  <p>
                    {{ new Date(insurances.createdAt).toLocaleString("pt-PT") }}
                  </p>
                </div>

                <div class="col-md-12">
                  <label>Método de Pagamento:</label>
                  <p>{{insurances.payment_method}}</p>
                </div>
                
                <div class="col-md-8">
                  <label>Easypay payment id:</label>
                  <p>{{ insurances.easypay_payment_id }}</p>
                </div>

                <div class="col-md-4">
                  <label>Order code:</label>
                  <p>{{ insurances.order_code }}</p>
                </div>

                <div class="col-md-6">
                  <label>Status:</label>
                  <div v-if="insurances.status == 0">
                    <p>Apólice não enviada</p>
                  </div>
                  <div v-if="insurances.status == 1">
                    <p>Apólice enviada</p>
                  </div>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <strong>Price:</strong>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-md-4">
                  <label>Total:</label>
                  <h4>{{ insurances.price }} €</h4>
                </div>
                <div class="col-md-4">
                  <label>Discount:</label>
                  <p v-if="insurances.promotional">
                    {{ insurances.promotional.discount }} %
                  </p>
                  <p v-else>0%</p>
                </div>
                <div class="col-md-4">
                  <label>Promotional code:</label>
                  <p v-if="insurances.promotional">
                    {{ insurances.promotional.code }} (id:
                    {{ insurances.promotional.id }})
                  </p>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol sm="12">
          <CCard v-for="(person, index) in insurances.persons" :key="index">
            <CCardHeader>
              <strong>Person </strong>{{ index + 1 }}
              <strong v-if="person.tomador">| Tomador </strong>
              <strong v-if="person.segurado">| Segurado </strong>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-md-4">
                  <label>Name:</label>
                  <p>{{ person.name }}</p>
                </div>
                <div class="col-md-4">
                  <label>Surname:</label>
                  <p>{{ person.surname }}</p>
                </div>
                <div class="col-md-4">
                  <label>NIF:</label>
                  <p>{{ person.nif }}</p>
                </div>
                <div class="col-md-4">
                  <label>Cartão Cidadão:</label>
                  <p>{{ toUpper(person.cc) }}</p>
                </div>
                <div class="col-md-4">
                  <label>Bilhete de Identidade:</label>
                  <p>{{ person.bi }}</p>
                </div>
                <div class="col-md-4">
                  <label>Passaporte:</label>
                  <p>{{ person.passport }} - {{person.passport_country}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label>Date of birth:</label>
                  <p>
                    {{
                      new Date(person.date_of_birth).toLocaleDateString("pt-PT")
                    }}
                  </p>
                </div>
                <div class="col-md-4">
                  <label>Mobile number:</label>
                  <p>{{ person.mobile_number }}</p>
                </div>
                <div class="col-md-4">
                  <label>Email:</label>
                  <p>{{ person.email }}</p>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>

        <CCol sm="12">
          <CCard v-for="(adress, index) in insurances.addresses" :key="index">
            <CCardHeader>
              <strong>Address Segurado </strong>
            </CCardHeader>
            <CCardBody>
              <div class="row">
                <div class="col-md-4">
                  <label>City:</label>
                  <p>{{ adress.city }}</p>
                </div>
                <div class="col-md-4">
                  <label>Country:</label>
                  <p>{{ adress.country }}</p>
                </div>
                <div class="col-md-4">
                  <label>Postal code:</label>
                  <p>{{ adress.postal_code }}</p>
                </div>
                <div class="col-md-6">
                  <label>Location:</label>
                  <p>{{ adress.location }}</p>
                </div>
                <div class="col-md-6">
                  <label>Address:</label>
                  <p>{{ adress.line_1 }}</p>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <template #footer>
        <CButton @click="largeModal = false" color="success">Ok</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import InsuranceDataService from "@/services/InsuranceDataService";

export default {
  name: "Sales",
  data() {
    return {
      selected: "Month",
      tableInsurances: [],
      tableFields: [
        { key: "id", label: "Num. Reserva", _classes: "text-center", sorter: 1, excel: true },
        { key: "createdAt", label: "Data de subscrição", excel: true },
        { key: "order_code", label: "Order code", excel: true },
        { key: "plano", label: "Plano", excel: true },
        { key: "price", label: "Preço", excel: true },
        { key: "payment_status", label: "Pagamento", excel: true },
        { key: "detail", label: "Detalhes" },
      ],
      largeModal: false,
      modalInsurances: "",

      yearsList: [],
      monthsList: [],
      selectedYear: 0,
      selectedMonth: 0,


      totalInsurances: 0.0,
      numInsurances: 0,
      totalSales: 0.0,
      liquid: 0.0,
      commission: 0.0,
      numSales: 0,
      totalNoSales: 0,
      numNoSales: 0,
    };
  },
  computed: {
    excelFields() {
      let fields = {
        "Num. Reserva": "id",
        "Código da Compra": "order_code",
        "Data de subscrição ": "created_at",
        Nome: "name",
        Sobrenome: "surname",
        "E-mail": "email",
        Telemóvel: "mobile_number",
        NIF: "nif",
        Países: "countries",
        "Data inicial": "initial_date",
        "Data Final": "final_date",
        "Qt. Pessoas": "people_quantity",
        Plano: "plan",
        Status: "status",
        "Método Pagto.": "payment_method",
        "Status Pagto.": "payment_status",
        Preço: "price",
        "Código de Desconto": "promotionalcode",
        Desconto: "discount",
      };
      return fields;
    },
    excelData() {
      return this.tableInsurances.map((d) => {
        const tomador = d.persons.find((p) => {
          return p.tomador === true;
        });
        let promotionalcode = "";
        let discount = "0%";
        if (d.promotional) {
          promotionalcode = d.promotional.code + " - id:" + d.promotional.id;
          discount = d.promotional.discount + "%";
        }

        return {
          id: d.id,
          order_code: d.order_code,
          created_at: new Date(d.createdAt).toLocaleDateString('pt-PT'),
          name: tomador ? tomador.name : "",
          surname: tomador ? tomador.surname : "",
          email: tomador ? tomador.email : "",
          mobile_number: tomador ? tomador.mobile_number : "",
          countries: d.countries,
          initial_date: d.initial_date,
          final_date: d.final_date,
          people_quantity: d.people_quantity,
          plan: d.plan,
          status: d.status ? "Apólice enviada" : "Apólice não enviada",
          payment_method: d.payment_method,
          payment_status: d.payment_status,
          price: d.price,
          promotionalcode: promotionalcode,
          discount: discount,
        };
      });
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    getBadge(status) {
      return status === "paid"
        ? "success"
        : status === "Inactive"
        ? "secondary"
        : status === "pending"
        ? "warning"
        : status === "Banned"
        ? "danger"
        : "primary";
    },
    getDados(id) {
      this.modalInsurances = this.tableInsurances.filter(function (event) {
        if (event.id == id) {
          return event;
        }
      });
      this.largeModal = true;
    },
    toUpper(str) {
      return str ? str.toUpperCase() : "";
    },
    loadYearsList(){
      let list = []
      const today = new Date()
      for(let i = 2022; i <= today.getFullYear(); i++){
        list.push(i)
      }
      if(list.length){
        this.selectedYear = list[list.length - 1]
        this.yearsList = list
      }
    },
    loadMonthsList(){
      const today = new Date()
      this.monthsList = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 
        'Julho','Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
      ]
      this.selectedMonth = today.getMonth() + 1
    },
    getReport(month = 0, year = 0){
      InsuranceDataService.getReport(month, year)
      .then((response) => {
        console.log(response.data.insurances)
        this.tableInsurances = response.data.insurances
        this.insurances =  response.data.insurances
        this.totalInsurances =  response.data.totalInsurances
        this.numInsurances =  response.data.numInsurances
        this.totalSales =  response.data.totalSales
        this.liquid =  response.data.liquid
        this.commission =  response.data.commission
        this.numSales =  response.data.numSales
        this.totalNoSales =  response.data.totalNoSales
        this.numNoSales =  response.data.numNoSales
      })
      .catch((e) => {
        alert(e);
      });
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.loadMonthsList()
    this.loadYearsList()
    this.getReport()
  },
};
</script>

<style scope="">
label {
  font-weight: bold;
}

.actions{
  float: right;
}

.filter{
  margin-right: 20px;
  display: inline-block;
}

.filter-item{
  display: inline-block;
  padding: 5px 5px;
}

.filter-item label{
  padding-right: 5px;
}

.btn-download-excel {
  border: 1px solid #eee;
  background: #eee;
  font-size: 11px;
  font-weight: bold;
  transition: 0.2s;
}

.btn-download-excel:hover {
  background: #efe;
  border: 1px solid #ada;
  color: #0c7238;
  transition: 0.2s;
}

.btn-download-excel img {
  width: 24px;
  margin-right: 10px;
  height: auto;
}
</style>